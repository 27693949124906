.market-matrix-viewer {
  width: 100%;
}

.markets-grid {
  display: grid;
  background-color: var(--color-surface);
  color: var(--color-text);
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 16px;
}

/* Market Container */
.market-container {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.market-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

/* Circles Container */
.circles-container {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.tam-circle {
  background-color: rgb(219, 234, 254);
}

.sam-circle {
  background-color: rgb(191, 219, 254);
}

.som-circle {
  background-color: rgb(147, 197, 253);
}

.circle-label {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: rgb(31, 41, 55);
  pointer-events: none;
}

/* CAGR Bars */
.cagr-container {
  width: 100%;
  margin-top: 10px;
  padding: 0 8px;
}

.cagr-heading {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-text);
  cursor: help;
}

.cagr-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.cagr-label {
  width: 32px;
  font-size: 12px;
  font-weight: 500;
}

.cagr-bar-container {
  flex: 1;
  height: 8px;
  background-color: rgb(243, 244, 246);
  border-radius: 4px;
  overflow: hidden;
}

.cagr-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.tam-bar {
  background-color: rgb(219, 234, 254);
}

.sam-bar {
  background-color: rgb(191, 219, 254);
}

.som-bar {
  background-color: rgb(147, 197, 253);
}

.cagr-value {
  width: 48px;
  font-size: 12px;
  text-align: right;
}

/* Legend */
.legend-container {
  margin-top: 24px;
  padding: 16px;
  border-top: 1px solid rgb(229, 231, 235);
}

.market-size-legend {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
  justify-content: center;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text);
}

.legend-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.tam-legend {
  background-color: rgb(219, 234, 254);
}

.sam-legend {
  background-color: rgb(191, 219, 254);
}

.som-legend {
  background-color: rgb(147, 197, 253);
}

.cagr-legend {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text-secondary);
}

/* Hover Effects */
.market-container:hover .circle {
  transform: scale(1.02);
}

.market-container:hover .cagr-bar {
  opacity: 0.9;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .markets-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .markets-grid {
    grid-template-columns: 1fr;
  }
}

/* Add glow_10's bubble styling */
.bubble-visualization {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-visualization circle {
  transition: all 0.3s ease;
  cursor: pointer;
}

.bubble-visualization circle:hover {
  opacity: 1;
  filter: brightness(1.1);
}

.bubble-visualization text {
  text-shadow: 0 1px 2px rgba(0,0,0,0.3);
  pointer-events: none;
}

/* Update the CAGR bar colors for secondary markets */
.tam-bar-secondary {
  background-color: #CCCCCC;
}

.sam-bar-secondary {
  background-color: #999999;
}

.som-bar-secondary {
  background-color: #666666;
}

/* Update legend to match first market colors */
.legend-circle.tam-legend {
  background-color: #FED38C;
}

.legend-circle.sam-legend {
  background-color: #FDB544;
}

.legend-circle.som-legend {
  background-color: #FD9800;
}

.visibility-controls {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  padding: 8px;
  background-color: var(--card-bg);
  border-radius: 8px;
  justify-content: center;
}

.premium-locked {
  position: relative;
  .glass-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--plan-glass-overlay-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    z-index: 10;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    .overlay-content {
      text-align: justify;
    }
  }
}

:root {
  /* Colors */
  --color-primary: #FD9800;
  --color-primary-light: rgba(253, 152, 0, 0.12);
  --color-primary-transparent: rgba(253, 152, 0, 0.2);
  --color-success: #28a745;
  --color-error: #dc3545;
  
  /* Light theme defaults */
  --color-surface: #FFFFFF;
  --color-surface-secondary: #F5F5F5;
  --color-background: #FFFFFF;
  --color-background-hover: rgba(0, 0, 0, 0.04);
  --color-text: #1C1C1E;
  --color-text-secondary: #6B7280;
  --color-text-inverse: #FFFFFF;
  --color-border: #E5E7EB;
  --shadow-color: rgba(0, 0, 0, 0.1);
  
  /* Typography */
  font-family: 'DM Sans', sans-serif;
}

/* Dark theme overrides */
.dark {
  --color-surface: #1C1C1E;
  --color-surface-secondary: #2C2C2E;
  --color-background: #000000;
  --color-background-hover: rgba(255, 255, 255, 0.04);
  --color-text: #FFFFFF;
  --color-text-secondary: #9CA3AF;
  --color-text-inverse: #1C1C1E;
  --color-border: #374151;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

/* Common transitions */
button, 
.icon-button, 
.process-button,
.collapse-button {
  transition: all 0.2s ease;
}

.App {
  text-align: center;
  padding: 0;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: var(--color-surface);
  box-shadow: 0 2px 4px var(--shadow-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  margin-bottom: 0;
}

.header-left {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Remove or adjust these if not needed */
.header-right {
  display: none;
}

/* Update App title position */
.App-header h1 {
  margin-left: auto;
  font-size: 24px;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-primary);
  padding: 6px;
  height: 28px;
  width: 28px;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #1C1C1E;
  background-color: var(--color-primary);
  border-radius: 5px;
  padding: 5px 10px;
}

.create-new-session {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--color-success);
  color: var(--color-text-inverse);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.create-new-session:hover {
  background-color: var(--color-success-hover);
}

.session-list-content {
  background-color: var(--color-surface);
  color: var(--color-text);
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  padding-top: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

table {
  width: 80%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 10px;
}

td {
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--border-color);
  box-shadow: 0 2px 2px -2px var(--shadow-color);
}

/* Update the base button styles */
button {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 20px;
  background-color: var(--color-primary);
  color: #1C1C1E;
  border: none;
  font-size: 14px;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #E68A00;
  transform: translateY(-1px);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #717585;
  transform: none;
}

/* Update specific button states */
button.running {
  background-color: var(--color-primary);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

/* Theme Variables - Light & Dark */
.theme-light, .theme-dark {
  --color-primary: #FD9800;
  --button-bg: #FD9800;
  --button-text: #1C1C1E;
  --button-hover: #E68A00;
  --button-text-hover: #90caf9;
}

/* Update action styles */
.loading-animation {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.dev-tool {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 400px;
  background-color: var(--color-surface);
  color: var(--color-text);
  padding: 20px;
  overflow-y: auto;
  box-shadow: -2px 0 5px var(--shadow-color);
  z-index: 999;
}

.dev-tool-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dev-tool-header h2 {
  margin: 0;
}

.close-button {
  background-color: transparent;
  color: var(--color-text);
  font-size: 24px;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}

.close-button:hover {
  color: var(--color-error);
  background-color: transparent;
}

.api-response {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.api-response button {
  width: 100%;
  text-align: left;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.api-response.success button {
  background-color: var(--color-success-light);
  color: var(--color-success-text);
}

.api-response.error button {
  background-color: var(--color-error-light);
  color: var(--color-error-text);
}

.api-response pre {
  background-color: var(--color-surface);
  padding: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 300px;
  overflow-y: auto;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal.open {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background-color: var(--color-surface);
  padding: 20px;
  border: 1px solid var(--border-color);
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  transform: scale(0.7);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal.open .modal-content {
  transform: scale(1);
  opacity: 1;
}

.modal-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.response-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timestamp {
  font-size: 0.8em;
  color: #666;
}

.modal-actions {
  margin-bottom: 10px;
}

.modal-actions button {
  margin-right: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  z-index: 2000;
  animation: slideUp 0.3s ease-out;
}

.toast.success {
  background-color: #4caf50;
}

.toast.error {
  background-color: #f44336;
}

.toast.info {
  background-color: #2196f3;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.session-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.session-controls button {
  padding: 10px 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 12px;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.session-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.session-select-indicator {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.session-select-indicator:hover {
  transform: scale(1.2);
}

.session-name {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px;
}

.session-item button {
  padding: 5px 10px;
  margin-left: 5px;
  font-size: 12px;
}

.session-item button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.session-item input[type="text"] {
  flex-grow: 1;
  padding: 5px;
  margin-right: 5px;
}

.session-list-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.session-list-content {
  background-color: var(--color-surface);
  color: var(--color-text);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.session-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.session-list-header h3 {
  margin: 0;
  color: var(--color-text);
}

.close-button {
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 24px;
  cursor: pointer;
}

.session-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--color-surface);
  color: var(--color-text);
}

.session-item input[type="text"] {
  background-color: var(--color-surface);
  color: var(--color-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.delete-confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 400px;
}

.delete-confirmation-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.delete-confirmation-actions button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-confirm {
  background-color: #dc3545;
  color: white;
}

.delete-cancel {
  background-color: #6c757d;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 768px) {
  table {
    font-size: 14px;
  }

  button {
    padding: 5px 10px;
    font-size: 14px;
  }
}

main {
  padding-top: 70px;
}

.input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: var(--color-surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.input-container input {
  flex: 1;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 16px;
  background-color: var(--color-surface);
  color: var(--color-text);
}

/* Add specific styling for the prepare button */
.input-container .process-button.prepare-all {
  height: 48px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 200px;
  background-color: var(--color-primary);
  color: var(--button-text);
}

.input-container .process-button.prepare-all:disabled {
  opacity: 0.7;
  background-color: #717585 !important;
  color: #000000 !important;
  font-weight: 500;
}

.input-container input::placeholder {
  color: var(--color-text-secondary);
}

.input-container input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-light);
}

.dev-tool-button {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.dev-tool-button:hover {
  background-color: #0056b3;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-content {
  background-color: var(--color-surface);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: var(--color-text);
}

.loading-content p {
  margin-top: 10px;
  color: var(--color-text);
  font-size: 16px;
}

/* Update existing styles */
.delete-confirmation-actions button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading animations for different states */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid var(--color-text-secondary);
  border-radius: 50%;
  border-top-color: var(--color-text);
  animation: spin 1s linear infinite;
}

/* List animations */
.session-item {
  animation: fadeIn 0.3s ease-out;
}

.session-item.deleting {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.editor-modal .modal-content {
  background-color: var(--color-surface);
  color: var(--color-text);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 0;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #252526;
  border-bottom: 1px solid #333;
  border-radius: 8px 8px 0 0;
}

.modal-title {
  color: #d4d4d4;
  font-size: 16px;
  font-weight: 500;
}

.modal-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon-action {
  background: none;
  border: none;
  color: #d4d4d4;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.icon-action:hover {
  background-color: #404040;
  color: #ffffff;
}

.editor-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.editor-container textarea {
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  color: #d4d4d4;
  border: none;
  padding: 20px;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  outline: none;
  tab-size: 2;
}

.editor-container textarea:focus {
  outline: none;
}

/* Add syntax highlighting styles */
.json-key {
  color: #9cdcfe;
}

.json-string {
  color: #ce9178;
}

.json-number {
  color: #b5cea8;
}

.json-boolean {
  color: #569cd6;
}

.json-null {
  color: #569cd6;
}

/* Toast modifications for editor theme */
.toast {
  background-color: #252526;
  color: #d4d4d4;
  border: 1px solid #333;
}

.toast.success {
  border-left: 4px solid #4CAF50;
}

.toast.error {
  border-left: 4px solid #f44336;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.confirmation-content {
  background-color: #252526;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #d4d4d4;
  border: 1px solid #333;
}

.confirmation-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.confirmation-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.confirm-button, .save-button {
  background-color: #28a745;
  color: white;
}

.discard-button {
  background-color: var(--color-error);
  color: var(--color-text);
}

.cancel-button {
  background-color: var(--color-text-secondary);
  color: var(--color-text);
}

.icon-action:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.process-viewer {
  text-align: left;
}

.target-group {
  background-color: var(--color-surface-secondary);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.target-group h3 {
  color: var(--color-text);
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--border-color);
}

.group-content {
  padding-left: 15px;
}

.group-content h4 {
  color: var(--color-text);
  margin: 15px 0 10px;
}

.group-content ul {
  list-style-type: none;
  padding-left: 0;
}

.group-content li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.group-content li:before {
  content: "•";
  color: var(--color-primary);
  position: absolute;
  left: 0;
}

.problem-solution {
  display: flex;  
  gap: 20px;
  margin-top: 15px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.section {
  background-color: var(--color-surface);
  flex: 1;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.section h4 {
  color: var(--color-primary);
  margin-top: 0;
}

.section p {
  margin: 0;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .problem-solution {
    grid-template-columns: 1fr;
  }
}

.dashboard-content {
  padding-top: 80px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 80px 20px 20px 20px;
}

.process-controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.process-column {
  width: 100%;
  background-color: var(--color-surface);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: all 0.3s ease;
}

.process-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: var(--color-surface);
  border-radius: 8px 8px 0 0;
}

.process-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--color-text);
  text-align: center;
  width: 100%;
}

.collapse-button {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s ease;
}

.collapse-button:hover {
  color: var(--color-primary);
  background: none;
  transform: translateY(-50%);
}

/* Remove the hover movement for the process header collapse button */
.process-header .collapse-button:hover,
.process-header .collapse-button:active,
.process-header .collapse-button:focus {
  filter: none;
  background: none;
  box-shadow: none;
}

/* Optional: Add rotation animation for the arrow */
.collapse-button span {
  display: inline-block;
  transition: transform 0.2s ease;
}

.collapse-button.collapsed span {
  transform: rotate(-90deg);
}

.process-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.target-group-input {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  padding: 0;
  margin: 0;
}

.target-group-input label {
  font-weight: 500;
  color: var(--color-text);
  white-space: nowrap;
}

.target-group-count-input {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 0.9rem;
}

.target-group-count-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-transparent);
}

.process-actions button {
  padding: 10px 20px;
  border-radius: 4px;
}

.process-view-container {
  /* background-color: var(--color-surface); */
  /* color: var(--color-text); */
  border-radius: 8px;
  padding: 15px;
  box-shadow: inset 0 2px 4px var(--shadow-color);
}

/* Animation for collapse/expand */
.process-column.collapsed .process-view-container {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .process-actions {
    flex-direction: column;
  }
  
  .process-actions button {
    width: 100%;
  }
}

/* Theme Variables - Light */
.theme-light {
  /* Primary Colors */
  --color-primary: #FD9800;
  --color-background: #F5F5F5;
  --color-surface: #FFFFFF;
  --color-surface-secondary: #f8f9fa;
  --color-text: #1C1C1E;
  --color-text-secondary: #717585;
  --color-text-inverse: #FFFFFF;
  --color-border: rgba(0, 0, 0, 0.23);;
  
  /* Component Colors */
  --button-bg: #FD9800;
  --button-text: #1C1C1E;
  --button-hover: #E68A00;
  --button-text-hover: #90caf9;
  --card-bg: #FFFFFF;
  --card-border: rgba(0,0,0,0.1);
  --header-bg: #FFFFFF;
  --plan-glass-overlay-bg: rgb(245, 245, 245, 0.5);
  
  /* Status Colors */
  --color-success: #28a745;
  --color-success-hover: #218838;
  --color-success-light: #d4edda;
  --color-success-text: #155724;
  --color-error: #dc3545;
  --color-error-light: #f8d7da;
  --color-error-text: #721c24;
  
  /* Utility Colors */
  --shadow-color: rgba(0,0,0,0.1);
}

/* Theme Variables - Dark */
.theme-dark {
  /* Primary Colors */
  --color-primary: #FD9800;
  --color-background: #1C1C1E;
  --color-surface: #282828;
  --color-surface-secondary: #1C1C1E;
  --color-text: #F5F5F5;
  --color-text-secondary: #717585;
  --color-text-inverse: #1C1C1E;
  --color-border: rgba(255, 255, 255, 0.12);
  
  /* Component Colors */
  --button-bg: #FD9800;
  --button-text: #F5F5F5;
  --button-hover: #E68A00;
  --button-text-hover: #90caf9;
  --card-bg: #4b4b4b;
  --card-border: rgba(255, 255, 255, 0.12);
  --header-bg: #1C1C1E;
  --plan-glass-overlay-bg: rgba(28, 28, 30, 0.5);
  
  /* Status Colors */
  --color-success: #2ea043;
  --color-success-hover: #3fb950;
  --color-success-light: #238636;
  --color-success-text: #F5F5F5;
  --color-error: #f85149;
  --color-error-light: #da3633;
  --color-error-text: #F5F5F5;
  
  /* Utility Colors */
  --shadow-color: rgba(0,0,0,0.3);
}

/* Theme Toggle Switch */
.theme-switch {
  position: static;
  display: flex;
  align-items: center;
  gap: 8px;
}

.theme-switch .icon-button {
  font-size: 14px;
  padding: 4px;
  height: 24px;
  width: 24px;
}

.theme-switch .icon-button svg {
  width: 14px;
  height: 14px;
}

/* Base styles using CSS variables */
body {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-primary);
  transition: background-color 0.3s, color 0.3s;
}

.process-column {
  background-color: var(--card-bg);
  border: 1px solid var(--card-border);
  color: var(--color-text);
}

button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  transition: all 0.3s ease;
}

button:hover {
  background-color: var(--button-hover);
  color: var(--button-text-hover);
}

/* Optional: adjust the container's padding if needed */
.input-container {
  padding: 20px 30px;
}

.App-header .logo {
  height: 30px;
  width: auto;
  margin-left: auto;
}

/* Desktop version */
@media (min-width: 768px) {
  .App-header .logo {
    height: 45px;
  }
}

/* Update logo container styles */
.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header .logo {
  height: 30px;
  width: auto;
}

/* Desktop version */
@media (min-width: 768px) {
  .App-header .logo {
    height: 45px;
  }
}

/* Add these new styles */
.main-title {
  text-align: center;
  margin: 80px auto 30px;
  padding: 0 0px;
  color: var(--color-text);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  max-width: 800px;
}

/* Desktop version */
@media (min-width: 768px) {
  .main-title {
    font-size: 2.5rem;
    margin: 100px auto 40px;
  }
}

/* Update category styling */
.process-category[data-category="business"] {
  order: 1;
}

.process-category[data-category="text"] {
  order: 2;
}

.process-category[data-category="pictures"] {
  order: 3;
}

.process-category {
  margin-bottom: 40px;
  background-color: var(--color-surface);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px var(--shadow-color);
  display: flex;
  gap: 20px;
}

.category-title {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: var(--color-text-inverse);
  font-size: 1.8rem;
  padding: 20px 10px;
  background-color: var(--color-primary);
  border-radius: 4px;
  height: auto;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.category-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .process-category {
    padding: 15px;
    gap: 10px;
  }
  
  .category-title {
    font-size: 1.5rem;
    padding: 15px 5px;
    min-height: 150px;
  }
}

/* Add these new styles for ProcessViewer2 */
.scenes-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.scene-card {
  background-color: var(--color-surface);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.scene-card h3 {
  margin: 0;
  padding: 10px 0;
  color: var(--color-text);
  display: flex;
  align-items: center;
  gap: 10px;
}

.scene-content {
  margin-top: 15px;
  padding-left: 20px;
  display: grid;
  gap: 15px;
}

.scene-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.scene-item strong {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

.scene-item p {
  margin: 0;
  color: var(--color-text);
}

/* Add these new styles for ProcessViewer3 */
.frame-card {
  background-color: var(--color-surface);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-top: 10px;
  padding: 15px;
}

.frame-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.frame-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.frame-item strong {
  color: var(--color-primary);
  font-size: 0.9rem;
}

.frame-item p {
  margin: 0;
  padding-left: 10px;
  border-left: 2px solid var(--color-primary);
  font-size: 0.9rem;
}

.frame-details {
  background-color: var(--color-background);
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
}

.scene-details {
  background-color: var(--color-background);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.frames-container {
  margin-top: 15px;
}

.frames-container h5 {
  color: var(--color-primary);
  margin: 0 0 10px 0;
}

.target-group-input {
  background-color: var(--color-surface);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.target-group-input label {
  font-weight: 500;
  color: var(--color-primary);
}

.target-group-count-input {
  width: 80px;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 1rem;
}

.target-group-count-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-transparent);
}

.target-groups-container {
  margin-top: 15px;
}

.process-button {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 20px;
  background-color: var(--color-primary);
  color: #1C1C1E;
  border: none;
  font-size: 14px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 120px;
  position: relative;
  overflow: hidden;
}

.process-button:hover {
  background-color: #E68A00;
  transform: translateY(-1px);
}

.process-button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  background-color: #717585 !important;
  pointer-events: none !important;
}

.process-button.running {
  background-color: var(--color-primary);
  animation: pulse 1.5s infinite;
}

/* Add !important to ensure these styles take precedence */
button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-color: #717585 !important;
  pointer-events: none !important;
  transform: none !important;
}

.running:disabled {
  background-color: var(--color-primary) !important;
  opacity: 1 !important;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.process-button.loading {
  background-color: rgba(255, 140, 0, 0.7) !important; /* Transparent orange */
  color: white !important;
  cursor: not-allowed;
  opacity: 1 !important;
}

.process-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  filter: grayscale(100%);
}

/* Dark theme variations */
.theme-dark .process-button.loading {
  background-color: #2a2a2a;
  color: #999;
}

/* Make sure non-action buttons stay clickable */
.collapse-button {
  opacity: 1 !important;
  cursor: pointer !important;
}

/* Loading spinner styles */
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.process-button:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
  background-color: #717585 !important;
  color: #000000 !important;
  pointer-events: none !important;
  font-weight: 500;
}

.process-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  filter: grayscale(100%);
  color: #000000;
  font-weight: 500;
}

/* Dark theme specific */
.theme-dark .process-button:disabled,
.theme-dark .process-button.disabled {
  background-color: #4a4a4a !important;
  color: #000000 !important;
  font-weight: 500;
}

.input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.input-container input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input-container .process-button {
  white-space: nowrap;
}

/* Add these new styles for the finished state and rerun button */
.process-button.finished {
  background-color: var(--color-success) !important;
  color: white !important;
  opacity: 1 !important;
  cursor: default !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px !important;
  pointer-events: auto !important;
}

.process-button.finished:disabled {
  background-color: var(--color-success) !important;
  opacity: 1 !important;
  color: white !important;
}

/* Ensure the rerun icon remains clickable even when button is disabled */
.process-button.finished .rerun-icon {
  cursor: pointer !important;
  pointer-events: auto !important;
}

/* Adjust the existing button styles */
.process-button {
  /* ... existing styles ... */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.main-content {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1800px;
  margin: 0 auto;
}

.icicle-section {
  width: 97.5%;
  margin: 20px auto;
  height: 400px;
  background-color: var(--color-surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

/* Add media queries for mobile */
@media (max-width: 768px) {
  .icicle-section {
    width: 98%;
    height: 150px;
    margin: 60px auto 15px;
  }
}

/* Fix header icon sizes */
.App-header .icon-button {
  font-size: 1.2rem;
  padding: 8px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure consistent icon sizes */
.App-header svg {
  width: 20px;
  height: 20px;
}

/* Update the theme switch styles */
.theme-switch {
  position: static;
  display: flex;
  align-items: center;
  gap: 8px;
}

.theme-switch .icon-button {
  font-size: 14px;
  padding: 4px;
  height: 24px;
  width: 24px;
}

.theme-switch .icon-button svg {
  width: 14px;
  height: 14px;
}

/* Add specific styles for theme toggle icons */
.theme-switch .icon-button {
  font-size: 14px;
  padding: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Specific size for theme toggle icons */
.theme-switch .icon-button svg {
  width: 14px;
  height: 14px;
}

/* Keep the general header icon styles for other icons */
.App-header .icon-button:not(.theme-switch .icon-button) {
  font-size: 1.2rem;
  padding: 8px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Keep the general header icon sizes for other icons */
.App-header svg:not(.theme-switch svg) {
  width: 20px;
  height: 20px;
}

/* Base icon styles */
.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-primary);
  padding: 6px;
  height: 28px;
  width: 28px;
  transition: color 0.3s ease;
}

/* Header specific icon styles */
.App-header .icon-button {
  font-size: 16px;
  padding: 6px;
  height: 32px;
  width: 32px;
}

.App-header .icon-button svg {
  width: 16px;
  height: 16px;
}

/* Theme switch specific styles */
.theme-switch .icon-button {
  font-size: 14px;
  padding: 4px;
  height: 24px;
  width: 24px;
}

.theme-switch .icon-button svg {
  width: 14px;
  height: 14px;
}

/* Modal action icons */
.modal-actions .icon-action {
  font-size: 14px;
  padding: 4px;
  height: 28px;
  width: 28px;
}

.modal-actions .icon-action svg {
  width: 14px;
  height: 14px;
}

/* session list icons */
.session-select-indicator svg {
  width: 16px;
  height: 16px;
}

/* Process button icons */
.process-button svg {
  width: 14px;
  height: 14px;
}

.rerun-icon svg {
  width: 12px;
  height: 12px;
}

/* Add these new styles at the end of your existing CSS file */
.icicle-section {
  width: 97.5%;
  margin: 80px auto 20px;
  height: 400px;
  background-color: var(--color-surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.icicle-container {
  width: 100%;
  height: 100%;
}

.session-base-info {
  background-color: var(--color-surface);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.session-base-info h3 {
  margin: 0 0 15px 0;
  color: var(--color-text);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 10px;
}

.base-details {
  display: grid;
  gap: 15px;
}

.audio-base {
  margin-top: 10px;
}

.audio-details {
  margin-left: 15px;
  padding: 10px;
  background-color: var(--color-background);
  border-radius: 4px;
}

.audio-details p {
  margin: 5px 0;
}

/* Base button styles with consistent hover effect */
button,
.icon-button,
.process-button,
.confirm-button,
.cancel-button,
.discard-button,
.save-button {
  transition: all 0.2s ease;
  will-change: filter, transform;
}

/* Consistent hover effect for all buttons */
button:hover:not(:disabled),
.icon-button:hover:not(:disabled),
.process-button:hover:not(:disabled),
.confirm-button:hover:not(:disabled),
.cancel-button:hover:not(:disabled),
.discard-button:hover:not(:disabled),
.save-button:hover:not(:disabled) {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  transform: translateY(-1px);
}

/* Specific button states */
.process-button.finished:hover:not(:disabled) {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  transform: translateY(-1px);
}

/* Icon container hover effects */
.icon-container:hover circle {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
}

/* session item hover effects */
.session-item:hover {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  transform: translateY(-1px);
}

/* Theme switch hover effects */
.theme-switch .icon-button:hover {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  transform: translateY(-1px);
}

/* Collapse button hover effects */
.collapse-button:hover {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  transform: translateY(-1px) translateY(-50%);
}

/* Disabled state - no hover effects */
button:disabled,
.icon-button:disabled,
.process-button:disabled,
.confirm-button:disabled,
.cancel-button:disabled,
.discard-button:disabled,
.save-button:disabled {
  filter: none;
  transform: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.slider-container {
  margin: 15px 0;
  padding: 10px;
  background-color: var(--color-background);
  border-radius: 4px;
}

.styled-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: var(--color-text-secondary);
  outline: none;
  margin: 10px 0;
}

.styled-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.styled-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}
/* Add these new styles for editable content */
.editable-field {
  margin: 10px 0;
}

.editable-input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-text);
  margin-top: 4px;
}

.editable-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-transparent);
}

.viewer-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.edit-toggle {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--color-surface);
  border: 1px solid var(--border-color);
}

.edit-toggle.active {
  background-color: var(--color-primary);
  color: var(--color-text-inverse);
}

.save-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--color-success);
  color: var(--color-text-inverse);
}
/* Add tooltip on hover */
.style-selector {
  margin: 15px 0;
  padding: 10px;
  background-color: var(--color-background);
  border-radius: 4px;
}

.style-selector-label {
  display: block;
  margin-bottom: 10px;
  color: var(--color-text);
  font-weight: 500;
}

.style-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.style-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  background-color: var(--color-surface);
  color: var(--color-text);
  cursor: pointer;
  transition: all 0.2s ease;
}

.style-button:hover:not(.active) {
  background-color: var(--color-background-secondary);
}

.style-button.active {
  background-color: var(--color-primary);
  color: var(--color-text-inverse);
  border-color: var(--color-primary);
}

.style-selector {
  margin: 15px 0;
  padding: 10px;
  background-color: var(--color-background);
  border-radius: 4px;
}

.style-selector-label {
  display: block;
  margin-bottom: 20px;
  color: var(--color-text);
  font-weight: 500;
  text-align: center;
}

.intensity-markers {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  position: relative;
}

.intensity-markers::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 20px;
  right: 20px;
  height: 2px;
  background-color: var(--color-border);
}

.intensity-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 60px;
  transition: all 0.2s ease;
}

.marker-line {
  width: 2px;
  height: 30px;
  background-color: var(--color-text);
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.intensity-marker.active .marker-line {
  background-color: var(--color-primary);
  height: 35px;
}

.marker-label {
  font-size: 0.9rem;
  color: var(--color-text);
  text-align: center;
  transition: all 0.2s ease;
}

.intensity-marker.active .marker-label {
  color: var(--color-primary);
  font-weight: 500;
}

.intensity-marker:hover .marker-line {
  height: 35px;
  background-color: var(--color-primary);
}

.intensity-marker:hover .marker-label {
  color: var(--color-primary);
}

.style-selector {
  margin: 20px 0;
  padding: 15px;
  background-color: var(--color-surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.style-selector-label {
  display: block;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-text);
  margin-bottom: 25px;
}

.intensity-scale {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
  position: relative;
}

/* Connecting line between dots */
.intensity-scale::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 25px;
  right: 25px;
  height: 2px;
  background-color: var(--color-border);
  z-index: 0;
}

.intensity-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  transition: all 0.2s ease;
  z-index: 1;
}

.intensity-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-surface);
  border: 2px solid var(--color-border);
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.intensity-line {
  width: 2px;
  height: 15px;
  background-color: var(--color-border);
  margin-bottom: 8px;
}

.intensity-label {
  font-size: 0.9rem;
  color: var(--color-text);
  text-align: center;
  max-width: 80px;
  transition: all 0.2s ease;
}

/* Active state */
.intensity-option.active .intensity-dot {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  transform: scale(1.2);
}

.intensity-option.active .intensity-line {
  background-color: var(--color-primary);
}

.intensity-option.active .intensity-label {
  color: var(--color-primary);
  font-weight: 500;
}

/* Hover state */
.intensity-option:hover .intensity-dot {
  border-color: var(--color-primary);
  transform: scale(1.1);
}

.intensity-option:hover .intensity-line {
  background-color: var(--color-primary);
}

.intensity-option:hover .intensity-label {
  color: var(--color-primary);
}

.intensity-control {
  background: var(--color-surface);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.intensity-header {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}

.intensity-scale {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 10px;
  gap: 4px;
}

/* Line connecting all steps */
.intensity-scale::before {
  content: '';
  position: absolute;
  left: 20px;
  right: 20px;
  top: 15px;
  height: 4px;
  background: var(--color-border);
  border-radius: 2px;
}

.intensity-step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.step-marker {
  width: 30px;
  height: 30px;
  background: var(--color-surface);
  border: 3px solid var(--color-border);
  border-radius: 50%;
  margin-bottom: 12px;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease;
}

.step-label {
  font-size: 0.9rem;
  color: var(--color-text);
  text-align: center;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

/* Active state */
.intensity-step.active .step-marker {
  border-color: var(--color-primary);
  background: var(--color-primary);
  transform: scale(1.1);
}

.intensity-step.active .step-label {
  color: var(--color-primary);
  font-weight: 500;
}

/* Hover effects */
.intensity-step:hover .step-marker {
  border-color: var(--color-primary);
  transform: scale(1.1);
}

.intensity-step:hover .step-label {
  color: var(--color-primary);
}

/* Fill line up to active step */
.intensity-step.active::before {
  content: '';
  position: absolute;
  left: -50%;
  right: 50%;
  top: 15px;
  height: 4px;
  background: var(--color-primary);
  z-index: 0;
}

.slider-container {
  margin: 15px 0;
  padding: 10px;
  background-color: var(--color-background);
  border-radius: 4px;
}

.slider-header {
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--color-text);
}

.slider-track {
  position: relative;
  padding: 0 20px;
  margin-bottom: 40px;
}

.slider-line {
  position: absolute;
  top: 15px;
  left: 40px;
  right: 40px;
  height: 2px;
  background: var(--color-border);
}

.slider-items {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.slider-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 60px;
  position: relative;
}

.item-marker {
  width: 2px;
  height: 20px;
  background-color: var(--color-border);
  margin-bottom: 15px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
}

.item-label {
  font-size: 0.9rem;
  color: var(--color-text);
  text-align: center;
  position: absolute;
  top: 25px;
  width: 100%;
  transition: all 0.2s ease;
}

.mark-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-surface);
  border: 2px solid var(--color-border);
  transition: all 0.2s ease;
}

.mark-label {
  position: absolute;
  top: 24px;
  font-size: 12px;
  color: var(--color-text-secondary);
  transform: translateX(-50%);
  white-space: nowrap;
  transition: color 0.2s ease;
}

/* Override ALL button hover effects for collapse button */
button.collapse-button {
  padding: 5px;
  background: none;
  border: none;
  transform: translateY(-50%);
}

button.collapse-button:hover {
  background: none;
  transform: translateY(-50%);  /* Keep the same transform */
  color: var(--color-text);     /* Keep the same color */
}

/* Remove ALL transitions for collapse button */
button.collapse-button {
  transition: none;
}

/* Make sure no other hover effects apply */
button.collapse-button:hover,
button.collapse-button:active,
button.collapse-button:focus {
  background: none;
  transform: translateY(-50%);
  box-shadow: none;
  filter: none;
}

/* Main prepare-all button states */
.process-button.prepare-all {
  min-width: 200px;  /* Minimum width to maintain consistency */
  width: auto;       /* Allow button to grow with content */
  max-width: none;   /* Remove any max-width constraints */
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
  padding: 8px 20px;   /* Consistent padding */
}

.process-button.prepare-all.loading {
  background-color: var(--color-primary);
  opacity: 0.8;
  cursor: not-allowed;
}

.process-button.prepare-all.finished {
  background-color: var(--color-success);
  color: white;
}

/* Loading spinner */
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Rerun icon */
.rerun-icon {
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.rerun-icon:hover {
  opacity: 1;
}

/* Add this after your other process-button states */
.process-button.waiting {
  background-color: #717585;  /* Using your disabled color */
  color: #000000;
  opacity: 0.7;
  cursor: not-allowed;
  font-weight: 500;
}

/* Dark theme variation */
.theme-dark .process-button.waiting {
  background-color: #4a4a4a;
  color: #000000;
}

/* Update the finished state to ensure it takes precedence */
.process-button.finished {
  background-color: var(--color-success) !important;
  color: white !important;
  opacity: 1 !important;
}

/* Update the loading state */
.process-button.loading {
  background-color: var(--color-primary) !important;
  opacity: 0.8;
  cursor: not-allowed;
  animation: pulse 1.5s infinite;
}

/* Add these new styles for the Glow11Processor */
.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.h-\[300px\] {
  height: 300px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

/* Main container */
.main-content {
  display: flex;
  height: calc(100vh - 60px); /* Subtract header height */
  margin-top: 60px; /* Add header height as margin */
  overflow: hidden;
}

/* Stepper/sidebar */
.stepper-section {
  width: 280px;
  min-width: 280px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid var(--border-color);
  background-color: var(--color-surface);
}

/* Main content area */
.process-content-area {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

/* Content wrapper */
.process-content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.glow_03 {

  display: flex;
  flex-direction: column;
  gap: 5px;

  .group-info-grid {
    margin-left: unset;
  }
  .action-bar-containter {
    padding: 10px;
    gap: 5px;
    background-color: var(--color-surface);
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--card-border);    
    border-radius: 8px;
    min-width: 185px;
  }
  /* Add these new styles for ProcessViewer2 */
  .scenes-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
  }
  .group-card,
  .scene-card, 
  .frame-card {
    background-color: var(--color-surface);
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin-top: unset;
    padding: unset;
    width: 100%;
    min-height: 100px;
    position: inherit;
    &.selected {
      min-width: 250px;
      max-width: 35%;
    }
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
    &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
  }

  .group-card {
    max-width: 350px;
  }

  .scene-card h3 {
    margin: 0;
    padding: 10px 0;
    color: var(--color-text);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .group-image,
  .scene-image, 
  .frame-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .animation-estimate {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: var(--color-background);
    color: var(--color-primary);
    padding: 5px;
    border-radius: 8px;
    font-size: 0.8rem;
    max-width: calc(100% - 10px);
  }

  .scene-image {
    width: 100%;
    max-width: unset;
  }

  .group-image img, .scene-image img,.frame-image img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    /* transition: transform 0.5s ease; */
  }

  .minimize {
    max-height: 150px !important;
  }

  .scene-image .MuiButton-root {
    margin: 10px;
  }

  /* Add these new styles for ProcessViewer3 */
  .group-content,
  .scene-content,
  .frame-content {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: unset;
    padding-left: unset;
    justify-content: space-evenly;
    border-radius: 8px;
    max-height: 350px;
    position: relative;
  }
  

  .group-content {
    /* @media (max-width: 768px) {
      flex-direction: column;
    } */
    transition: .3s;
    &.selected {
      height: 350px;
    }
    &:hover, &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
  }

  .group-details {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: unset;
    padding-left: unset;
    justify-content: space-evenly;
    border-radius: 8px;
    max-height: 350px;
    position: relative;
    width: 100%;
  }
  

  .scene-content,
  .frame-content {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  
  .scene-content .scene-content-actions,
  .frame-content .frame-content-actions {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    height: 100%;
    transform: translateX(0);
    transition: 0.3s;
    opacity: 0;
  }

  .scene-content:hover .scene-content-actions,
  .frame-content:hover .frame-content-actions {
    gap: 5px;
    transform: translateX(100%);
    opacity: 1;
  }

  .scene-wrapper, .frame-wrapper {
    display: flex;
    transition: 0.5s;
    position: relative;
    border-radius: 8px;
    gap: 5px;
    * {
      word-break: break-all;
    }
    &:hover, &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
      filter: unset !important;
    }
    &.selected {
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
      * {
        word-break: normal;
      }
    }
    &.unselected {
      filter: grayscale(100%);
    }
  }

  .scene-content-other-nodes {
    display: flex;
    width: 30%;
  }

  .frame-item,
  .scene-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    :is(p) {
      margin: 0;
      padding-left: 10px;
      border-left: 2px solid var(--color-primary);
      font-size: 0.9rem;
    }
  }

  .group-detail,
  .frame-details,
  .scene-details {
    background-color: var(--color-background);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--card-border);
    margin-top: unset;
    margin-bottom: unset;
    overflow-y: auto;
    scrollbar-width: thin;
    flex-grow: 2;
    width: 100%;
    :is(strong) {
      color: var(--color-primary) !important;
      font-size: 0.9rem;
    }
    :is(h4){
      margin: 0;
      padding: 10px 0;
      color: var(--color-primary);
      text-align: center;
      font-weight: 700;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .frames-container {
    margin-top: 15px;
  }

  .frames-container h5 {
    color: var(--color-primary);
    margin: 0 0 10px 0;
  }
  
  .refresh-button {
    position: absolute;
    color: var(--color-primary);
    top: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 10;
    background-color: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 0 2px 4px var(--shadow-color);
    :hover {
      background-color: var(--card-bg);
    }
  }

  .node-action-buttons {
    position: absolute;
    color: var(--color-primary);
    top: 5px;
    left: 5px;
    display: flex;
    gap: 8px;
    .refresh-button {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .edit-button {
    position: absolute;
    color: var(--color-primary);
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 10;
    background-color: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 0 2px 4px var(--shadow-color);
    :hover {
      background-color: var(--card-bg);
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
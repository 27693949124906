.subscription-plans {
    .plan {
        padding: 24px;
        background-color: var(--card-bg);
        border: solid var(--color-border) 1px;
        border-radius: 12px;
        gap: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        &:hover {
            border: solid #6CC0FF70 1px;
        }
        :is(p) {
            margin-bottom: 0rem;
        }
        .plan-name {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            border-radius: 100px;
            width: auto;
            min-width: fit-content;
            gap: 8px;
            flex-wrap: nowrap;
            position: relative;
            justify-content: flex-start;
            white-space: nowrap;
        }
        .plan-studio-pro {        
            background-color: #F0DB3B1A;
            color: #F6E773;
        }
        .plan-producer {        
            background-color: #5FBAFF1A;
            color: #3DABFF;
        }
        .plan-plus {        
            background-color: #15E4B91A;
            color: #15E4B9;
        }
        .plan-lite {        
            background-color: #D48AFF1A;
            color: #BD4DFF;
        }
        .features {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #fff;
        }
        @media (max-width: 1200px) {
            gap: 12px;
        }
    }
    .link {
        color: #0d6efd;
    }    
}

.stripe-continue-button {
  background-color: var(--primary-color, #FD9800) !important;
  color: white !important;
  padding: 12px 24px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  font-size: 1.1rem !important;
  border-radius: 4px !important;
  transition: all 0.3s ease !important;
}

.stripe-continue-button:hover {
  opacity: 0.9 !important;
}

/* Plan colors for hover effects */
.plan-studio-pro .stripe-continue-button {
  background-color: #FD9800 !important; /* Gold/Orange */
}

.plan-producer .stripe-continue-button {
  background-color: #0EA5E9 !important; /* Blue */
}

.plan-plus .stripe-continue-button {
  background-color: #22C55E !important; /* Green */
}

.plan-lite .stripe-continue-button {
  background-color: #A855F7 !important; /* Purple */
}

/* Disable Firefox focus outline */
.stripe-continue-button::-moz-focus-inner {
  border: 0;
}

/* Better focus styles for accessibility */
.stripe-continue-button:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5) !important;
  outline-offset: 2px !important;
}

/* Enhanced selection effects - make selection more visible */
.subscription-plans .plan.selected {
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.05) !important; /* Subtle background highlight */
  transform: translateZ(0); /* Force GPU rendering for better performance */
}

/* Enhanced border pulse for selected plan */
.subscription-plans .plan.selected::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 15px;
  z-index: -1;
  animation: selected-plan-pulse 2s ease-in-out infinite;
}

/* Plan-specific enhanced glow colors */
.subscription-plans .plan.selected.plan-studio-pro::after {
  box-shadow: 0 0 30px rgba(253, 152, 0, 0.7) !important;
  border: 3px solid rgba(253, 152, 0, 0.9) !important;
}

.subscription-plans .plan.selected.plan-producer::after {
  box-shadow: 0 0 30px rgba(14, 165, 233, 0.7) !important;
  border: 3px solid rgba(14, 165, 233, 0.9) !important;
}

.subscription-plans .plan.selected.plan-plus::after {
  box-shadow: 0 0 30px rgba(34, 197, 94, 0.7) !important;
  border: 3px solid rgba(34, 197, 94, 0.9) !important;
}

.subscription-plans .plan.selected.plan-lite::after {
  box-shadow: 0 0 30px rgba(168, 85, 247, 0.7) !important;
  border: 3px solid rgba(168, 85, 247, 0.9) !important;
}

/* More visible pulse animation */
@keyframes selected-plan-pulse {
  0% { opacity: 0.7; box-shadow-opacity: 0.5; }
  50% { opacity: 1; box-shadow-opacity: 0.9; }
  100% { opacity: 0.7; box-shadow-opacity: 0.5; }
}

/* Make plan features in selected plan more visible */
.subscription-plans .plan.selected .features {
  transform: translateX(8px) !important;
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67) !important;
  opacity: 1 !important;
  color: white !important;
}

/* Remove "Selected" text and use a checkmark for consistency */
.subscription-plans .plan.selected .plan-name::after {
    content: "";
    display: none;
}

/* Add a checkmark indicator that doesn't change the width */
.subscription-plans .plan.selected .plan-name svg {
    /* Remove the green color */
    /* color: #4CAF50; */
    /* Color will be set per plan */
}

/* Plan-specific checkmark colors */
.subscription-plans .plan.selected.plan-studio-pro .plan-name svg,
.subscription-plans .plan.selected.plan-studio-pro .check-icon {
    color: #FD9800 !important;
}

.subscription-plans .plan.selected.plan-producer .plan-name svg,
.subscription-plans .plan.selected.plan-producer .check-icon {
    color: #0EA5E9 !important;
}

.subscription-plans .plan.selected.plan-plus .plan-name svg,
.subscription-plans .plan.selected.plan-plus .check-icon {
    color: #22C55E !important;
}

.subscription-plans .plan.selected.plan-lite .plan-name svg,
.subscription-plans .plan.selected.plan-lite .check-icon {
    color: #A855F7 !important;
}

/* Keep the shine animation */
.subscription-plans .plan.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%, /* Slightly brighter shine */
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-25deg);
  animation: shine 3s infinite;
}

/* Slightly faster shine animation */
@keyframes shine {
  0% {
    left: -100%;
  }
  15%, 100% {
    left: 150%;
  }
}

/* Enhanced check icon */
.check-icon-wrapper {
  position: absolute;
  right: 10px;
  margin-left: 0;
  width: auto;
}

.check-icon {
  font-size: 1.2rem !important;
  animation: check-pop 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
}

@keyframes check-pop {
  0% { transform: scale(0); opacity: 0; }
  70% { transform: scale(1.2); }
  100% { transform: scale(1); opacity: 1; }
}

/* Button entrance animation */
@keyframes button-entrance {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  70% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Modify hover behavior - remove vertical movement while keeping shadow */
.subscription-plans .plan:hover {
  /* Remove transform: translateY(-5px) !important; */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3) !important;
  transition: box-shadow 0.3s ease !important;
}

/* Ensure consistent width for all plans in the row */
.subscription-plans .MuiStack-root {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
}

/* Add a small badge to clearly indicate selected plan */
.subscription-plans .plan.selected .plan-name::before {
  content: "✓";
  position: absolute;
  right: -8px;
  top: -8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
  animation: badge-pulse 2s infinite;
}

/* Plan-specific badge background colors */
.subscription-plans .plan.selected.plan-studio-pro .plan-name::before {
  background-color: #FD9800;
  box-shadow: 0 0 10px #FD9800;
}

.subscription-plans .plan.selected.plan-producer .plan-name::before {
  background-color: #0EA5E9;
  box-shadow: 0 0 10px #0EA5E9;
}

.subscription-plans .plan.selected.plan-plus .plan-name::before {
  background-color: #22C55E;
  box-shadow: 0 0 10px #22C55E;
}

.subscription-plans .plan.selected.plan-lite .plan-name::before {
  background-color: #A855F7;
  box-shadow: 0 0 10px #A855F7;
}

@keyframes badge-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
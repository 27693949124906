/* styles.css */
.landing-page {  
  .bg-zinc-900 {
    background-color: var(--color-surface);
  }
  
  .bg-zinc-950 {
    background-color: #0f172a; /* Corresponds to zinc-950 */
  }
  
  .bg-zinc-900\/30 {
    background-color: rgba(27, 27, 27, 0.3); /* Corresponds to bg-zinc-900/30 */
  }
  
  .text-zinc-300 {
    color: var(--color-text-secondary);
  }
  
  /* Add any other necessary styles here */
  .bg-zinc-100 {
    background-color: #f9fafb; /* Corresponds to zinc-100 */
  }

  .bg-zinc-200 {
    background-color: #f3f4f6; /* Corresponds to zinc-200 */
  }

  .bg-zinc-300 {
    background-color: #e5e7eb; /* Corresponds to zinc-300 */
  }

  .bg-zinc-400 {
    background-color: #d1d5db; /* Corresponds to zinc-400 */
  }

  .bg-zinc-500 {
    background-color: #9ca3af; /* Corresponds to zinc-500 */
  }

  .bg-zinc-600 {
    background-color: #6b7280; /* Corresponds to zinc-600 */
  }

  .bg-zinc-700 {
    background-color: #4b5563; /* Corresponds to zinc-700 */
  }

  .bg-zinc-800 {
    background-color: #374151; /* Corresponds to zinc-800 */
  }

  .bg-zinc-900 {
    background-color: #1f2937; /* Corresponds to zinc-900 */
  }

  .bg-zinc-950 {
    background-color: #111827; /* Corresponds to zinc-950 */
  }

  .bg-zinc-100\/30 {
    background-color: rgba(249, 250, 251, 0.3); /* Corresponds to bg-zinc-100/30 */
  }

  .bg-zinc-200\/30 {
    background-color: rgba(243, 244, 246, 0.3); /* Corresponds to bg-zinc-200/30 */
  }

  .bg-zinc-300\/30 {
    background-color: rgba(229, 231, 235, 0.3); /* Corresponds to bg-zinc-300/30 */
  }

  .bg-zinc-400\/30 {
    background-color: rgba(209, 213, 219, 0.3); /* Corresponds to bg-zinc-400/30 */
  }

  .bg-zinc-500\/30 {
    background-color: rgba(156, 163, 175, 0.3); /* Corresponds to bg-zinc-500/30 */
  }

  .bg-zinc-600\/30 {
    background-color: rgba(107, 114, 128, 0.3); /* Corresponds to bg-zinc-600/30 */
  }

  .bg-zinc-700\/30 {
    background-color: rgba(75, 85, 99, 0.3); /* Corresponds to bg-zinc-700/30 */
  }

  .bg-zinc-800\/30 {
    background-color: var(--card-bg);
  }

  .bg-zinc-900\/30 {
    background-color: rgba(31, 41, 55, 0.3); /* Corresponds to bg-zinc-900/30 */
  }

  .bg-zinc-950\/30 {
    background-color: rgba(17, 24, 39, 0.3); /* Corresponds to bg-zinc-950/30 */
  }

  .text-zinc-100 {
    color: #f9fafb; /* Corresponds to text-zinc-100 */
  }

  .text-zinc-200 {
    color: #f3f4f6; /* Corresponds to text-zinc-200 */
  }

  .text-zinc-300 {
    color: var(--color-text-secondary);
  }

  .text-zinc-400 {
    color: var(--color-text-secondary);
  }

  .text-zinc-500 {
    color: #9ca3af; /* Corresponds to text-zinc-500 */
  }

  .text-zinc-600 {
    color: #6b7280; /* Corresponds to text-zinc-600 */
  }

  .text-zinc-700 {
    color: #4b5563; /* Corresponds to text-zinc-700 */
  }

  .text-zinc-800 {
    color: #374151; /* Corresponds to text-zinc-800 */
  }

  .text-zinc-900 {
    color: #1f2937; /* Corresponds to text-zinc-900 */
  }

  .text-zinc-950 {
    color: #111827; /* Corresponds to text-zinc-950 */
  }

  .text-zinc-100\/30 {
    color: rgba(249, 250, 251, 0.3); /* Corresponds to text-zinc-100/30 */
  }

  .text-zinc-200\/30 {
    color: rgba(243, 244, 246, 0.3); /* Corresponds to text-zinc-200/30 */
  }

  .text-zinc-300\/30 {
    color: rgba(229, 231, 235, 0.3); /* Corresponds to text-zinc-300/30 */
  }

  .text-zinc-400\/30 {
    color: rgba(209, 213, 219, 0.3); /* Corresponds to text-zinc-400/30 */
  }

  .text-zinc-500\/30 {
    color: rgba(156, 163, 175, 0.3); /* Corresponds to text-zinc-500/30 */
  }

  .text-zinc-600\/30 {
    color: rgba(107, 114, 128, 0.3); /* Corresponds to text-zinc-600/30 */
  }

  .text-zinc-700\/30 {
    color: rgba(75, 85, 99, 0.3); /* Corresponds to text-zinc-700/30 */
  }

  .text-zinc-800\/30 {
    color: rgba(56, 63, 71, 0.3); /* Corresponds to text-zinc-800/30 */
  }

  .text-zinc-900\/30 {
    color: rgba(31, 41, 55, 0.3); /* Corresponds to text-zinc-900/30 */
  }

  .text-zinc-950\/30 {
    color: rgba(17, 24, 39, 0.3); /* Corresponds to text-zinc-950/30 */
  }
  .text-zinc-100\/50 {
    color: rgba(249, 250, 251, 0.5); /* Corresponds to text-zinc-100/50 */
  }

  .text-zinc-200\/50 {
    color: rgba(243, 244, 246, 0.5); /* Corresponds to text-zinc-200/50 */
  }

  .text-zinc-300\/50 {
    color: rgba(229, 231, 235, 0.5); /* Corresponds to text-zinc-300/50 */
  }

  .text-zinc-400\/50 {
    color: rgba(209, 213, 219, 0.5); /* Corresponds to text-zinc-400/50 */
  }

  .text-zinc-500\/50 {
    color: rgba(156, 163, 175, 0.5); /* Corresponds to text-zinc-500/50 */
  }

  .text-zinc-600\/50 {
    color: rgba(107, 114, 128, 0.5); /* Corresponds to text-zinc-600/50 */
  }

  .text-zinc-700\/50 {
    color: rgba(75, 85, 99, 0.5); /* Corresponds to text-zinc-700/50 */
  }

  .text-zinc-800\/50 {
    color: rgba(56, 63, 71, 0.5); /* Corresponds to text-zinc-800/50 */
  }

  .text-zinc-900\/50 {
    color: rgba(31, 41, 55, 0.5); /* Corresponds to text-zinc-900/50 */
  }

  .text-zinc-950\/50 {
    color: rgba(17, 24, 39, 0.5); /* Corresponds to text-zinc-950/50 */
  }

  .bg-zinc-100\/50 {
    background-color: rgba(249, 250, 251, 0.5); /* Corresponds to bg-zinc-100/50 */
  }

  .bg-zinc-200\/50 {
    background-color: rgba(243, 244, 246, 0.5); /* Corresponds to bg-zinc-200/50 */
  }

  .bg-zinc-300\/50 {
    background-color: rgba(229, 231, 235, 0.5); /* Corresponds to bg-zinc-300/50 */
  }

  .bg-zinc-400\/50 {
    background-color: rgba(209, 213, 219, 0.5); /* Corresponds to bg-zinc-400/50 */
  }

  .bg-zinc-500\/50 {
    background-color: rgba(156, 163, 175, 0.5); /* Corresponds to bg-zinc-500/50 */
  }

  .bg-zinc-600\/50 {
    background-color: rgba(107, 114, 128, 0.5); /* Corresponds to bg-zinc-600/50 */
  }

  .bg-zinc-700\/50 {
    background-color: rgba(75, 85, 99, 0.5); /* Corresponds to bg-zinc-700/50 */
  }

  .bg-zinc-800\/50 {
    background-color: rgba(56, 63, 71, 0.5); /* Corresponds to bg-zinc-800/50 */
  }

  .bg-zinc-900\/50 {
    background-color: rgba(31, 41, 55, 0.5); /* Corresponds to bg-zinc-900/50 */
  }

  .bg-zinc-950\/50 {
    background-color: rgba(17, 24, 39, 0.5); /* Corresponds to bg-zinc-950/50 */
  }

  .hover\:bg-orange-500\/5 {
    &:hover {
      background-color: rgba(237, 137, 54, 0.05); /* Corresponds to bg-zinc-950/50 */
    }
  }
  .bg-orange-500\/10 {
    background-color: rgba(237, 137, 54, 0.1); /* Corresponds to bg-zinc-950/50 */
  }

  .border-zinc-100 {
    border: 1px solid #f9fafb; /* Corresponds to zinc-100 */
  }

  .border-zinc-200 {
    border: 1px solid #f3f4f6; /* Corresponds to zinc-200 */
  }

  .border-zinc-300 {
    border: 1px solid #e5e7eb; /* Corresponds to zinc-300 */
  }

  .border-zinc-400 {
    border: 1px solid #d1d5db; /* Corresponds to zinc-400 */
  }

  .border-zinc-500 {
    border: 1px solid #9ca3af; /* Corresponds to zinc-500 */
  }

  .border-zinc-600 {
    border: 1px solid #6b7280; /* Corresponds to zinc-600 */
  }

  .border-zinc-700 {
    border: 1px solid #4b5563; /* Corresponds to zinc-700 */
  }

  .border-zinc-800 {
    border: 1px solid #374151; /* Corresponds to zinc-800 */
  }

  .border-zinc-900 {
    border: 1px solid #1f2937; /* Corresponds to zinc-900 */
  }

  .border-zinc-950 {
    border: 1px solid #111827; /* Corresponds to zinc-950 */
  }

  /* Add theme-specific variables */
  &.light {
    --color-text-primary: #1C1C1E;
    --color-text-secondary: #6B7280;
    --color-background: #FFFFFF;
    --color-border: #E5E7EB;
    --card-bg: #FFFFFF;
  }

  &.dark {
    --color-text-primary: #FFFFFF;
    --color-text-secondary: #9CA3AF;
    --color-background: #1C1C1E;
    --color-border: #374151;
    --card-bg: #2C2C2E;
  }
}

/* Add these theme variables */
:root {
  --color-primary: #FD9800;
  --color-surface: #FFFFFF;
  --color-text: #1C1C1E;
  --color-text-secondary: #6B7280;
  --color-border: #E5E7EB;
  --card-bg: #FFFFFF;
}

/* Dark theme variables */
.dark {
  --color-surface: #1C1C1E;
  --color-text: #FFFFFF;
  --color-text-secondary: #9CA3AF;
  --color-border: #374151;
  --card-bg: #2C2C2E;
}

/* Light theme variables */
.light {
  --color-surface: #FFFFFF;
  --color-text: #1C1C1E;
  --color-text-secondary: #6B7280;
  --color-border: #E5E7EB;
  --card-bg: #FFFFFF;
}

/* Update your existing classes to use these variables */
.bg-zinc-900 {
  background-color: var(--color-surface);
}

.text-white {
  color: var(--color-text);
}

/* Add more theme-aware styles as needed */
.bg-zinc-800\/30 {
  background-color: var(--card-bg);
}

.text-zinc-300,
.text-zinc-400 {
  color: var(--color-text-secondary);
}

/* Add these styles for the horizontal scroll */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  position: relative;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Add margin for scroll buttons */
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/* Add container styles */
.min-w-min {
  min-width: max-content;
}

/* Ensure consistent aspect ratio for thumbnails */
.aspect-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.aspect-video img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Add text truncation utilities if not already present */
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-title {
  color: var(--color-text);
}

/* Form placeholder styling */
input::placeholder,
textarea::placeholder {
  color: rgba(156, 163, 175, 0.8) !important;
  opacity: 1;
}

/* Form input text color */
input, textarea, select {
  color: white !important;
}

/* Fix dropdown options background color */
select option {
  background-color: #18181b !important; /* zinc-900 */
  color: white !important;
}
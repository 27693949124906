
#subscriptions-center {
    z-index: 1200;
    position: absolute;
    top: 59px;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    padding: 5px 12px 8px 12px;
    color: var(--color-text);
    border-radius: 0px 0px 15px 15px;
    left: 0;
    right: 0;
    width: fit-content;
    border-left: solid 1px var(--color-border);
    border-bottom: solid 1px var(--color-border);
    border-right: solid 1px var(--color-border);
    background-color: var(--header-bg);
    #subscriptions-info {
        display: flex;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;
        letter-spacing: 0.01em;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;    
        gap: 8px;
        align-items: center;
    }    
    #credit-balance {
        color: var(--color-text) !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;
        letter-spacing: 0.01em;
        text-align: center;
    }
    &.register, &.guest{
        background-color: var(--header-bg);
    }
    &.user03_01_lite {
        background-color: #D48AFF1A;
        color: #BD4DFF;
    }
    &.user03_02_plus {
        background-color: #15E4B91A;
        color: #15E4B9;
    }
    &.user03_03_producer {
        background-color: #5FBAFF1A;
        color: #3DABFF;
    }
    &.user03_04_studio_pro {
        background-color: #F0DB3B1A;
        color: #F6E773;
    }
}